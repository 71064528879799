import React from 'react'

const ProductosHeader = ({ tipoProducto, tipoProductos, onSetTipoProducto }) => {
	return (
		<section className="products family-filter d-none d-md-block">

			<div className="container">

				<div className="row">

					<div className="buttons col-md-12">

						<div className="wrapper w-100">

							{tipoProductos?.map((tp) => (
								<div
									style={{ cursor: "pointer" }}
									className={`button w-100 p-4 text-center ${tp.idTipoProducto === tipoProducto?.idTipoProducto ? "active" : ""}`}
									title={tp.nombre}
									onClick={() => onSetTipoProducto(tp)}>
									{tp.nombre}
								</div>
							))}

						</div>


					</div>

				</div>

			</div>

		</section>
	)
}

export default ProductosHeader;