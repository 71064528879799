import React, { useState, useCallback } from 'react'
import { SetupTabValueContext, SetupTabActionsContext } from './SetupTabContext'
import { fetchUrl } from 'modules/util/functions'
import * as Notifications from 'modules/Notifications'

const SetupTabContainer = (props) => {
    const initialState = () => {
        return {
            form: {},
            dirty: false
        }
    }

    const [state, setState] = useState(initialState());

    // Privates
    const get = (urls, getRequest) => {
        fetchUrl(urls.get, "get", getRequest(), {
            success: (r) => {
                setState(ps => ({
                    ...ps,
                    form: r.data,
                    dirty: false
                }));
            },
            error: (r) => {
                Notifications.error(r.errorMessage);
            }
        });
    }

    const save = () => {
        const url = props.adding ? state.urls.create : state.urls.save;

        fetchUrl(url, "post", state.saveRequest(state.form), {
            success: (r) => {
                Notifications.success(r.message);

                if (!props.adding)
                    get(state.urls, state.getRequest);
                else
                    props.onCreate(r.data.entityId);
            },
            error: (r) => {
                Notifications.error(r.errorMessage);
            }
        });
    }

    const dirt = () => {
        setState(ps => ({
            ...ps,
            dirty: true
        }));
    }

    // Handlers
    const handleSubmit = () => {
        save();
    }

    const handleFormChange = (form) => {
        setState(ps => ({
            ...ps,
            form,
            dirty: true
        }));
    }

    const handleInit = useCallback((configuration) => {
        setState(ps => ({
            ...ps,
            ...configuration
        }));

        if (!props.adding)
            get(configuration.urls, configuration.getRequest);
    }, [props.adding]);

    const handleCancel = () => {
        get(state.urls, state.getRequest);

        setState(ps => ({
            ...ps,
            ...initialState()
        }));
    }

    const handleSuccess = (message) => {
        Notifications.success(message);
    }

    const handleError = (message) => {
        Notifications.error(message);
    }

    const values = {
        form: state.form,
        selectedId: props.selectedId,
        dirty: state.dirty,
        adding: props.adding
    };

    const actions = {
        onFormChange: handleFormChange,
        onInit: handleInit,
        onSubmit: handleSubmit,
        onCancel: handleCancel,
        onDelete: props.onDelete,
        onSuccess: handleSuccess,
        onError: handleError,
        dirt
    };

    return (
        <SetupTabValueContext.Provider value={values}>
            <SetupTabActionsContext.Provider value={actions}>
                {props.children}
            </SetupTabActionsContext.Provider>
        </SetupTabValueContext.Provider>
    )
}

export default SetupTabContainer;