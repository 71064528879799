import { createContext, useContext } from 'react'

export const SetupSearchValueContext = createContext(null);

export const useSetupSearchValueContext = () => {
    const context = useContext(SetupSearchValueContext);

    if (!context) {
        throw new Error('SetupSearchValueContext cannot be used outside the SetupSearchValueContext.Provider');
    }

    return context;
};

export const SetupSearchActionsContext = createContext(null);

export const useSetupSearchActionsContext = () => {
    const context = useContext(SetupSearchActionsContext);

    if (!context) {
        throw new Error('SetupSearchActionsContext cannot be used outside the SetupSearchActionsContext.Provider');
    }

    return context;
};
