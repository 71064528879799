import React, { forwardRef, useImperativeHandle } from 'react'
import { Store, ReactNotifications } from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'
import 'animate.css/animate.min.css'

let toastrRef;

export const refHook = (ref) => {
    toastrRef = ref;
}

export const success = (message) => {
    toastrRef && toastrRef.notify("success", message);
}

export const error = (message) => {
    toastrRef && toastrRef.notify("danger", message);
}

export const Toastr = forwardRef((_, ref) => {
    // API
    useImperativeHandle(ref, () => {
        return {
            notify(type, message) {
                Store.addNotification({
                    message,
                    type,
                    container: 'top-right',
                    animationIn: ["animate__animated animate__bounceIn"],
                    animationOut: ["animate__animated animate__bounceOut"],
                    dismiss: {
                        duration: 3000,
                        pauseOnHover: true
                    }
                });
            }
        }
    });

    return (
        <ReactNotifications />
    )
});

export default Toastr;