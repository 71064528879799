import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import VoltajeInfoTab from './voltajeInfo/VoltajeInfoTab'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

const VoltajeSetup = () => {

	const setupProps = {
		title: "Voltajes",
		icon: faSquare,
		addNewTitle: "Crear voltaje",
		searchUrl: "/voltajeSetup/search",
		rowKey: "idVoltaje",
		columns: [
			{ key: "idVoltaje", name: "ID", sortable: true, width: 60 },
			{ key: "nombre", name: "Nombre", sortable: true },
		],
		tabs: [
			{ key: "info", title: "Info", comp: VoltajeInfoTab }
		]
	};

	return (
		<SetupPage
			setupProps={setupProps}
			search={Search} />
	)
}

export default VoltajeSetup;