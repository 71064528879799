import React from 'react';
import { useNavigate } from 'react-router-dom';

const ProductCard = ({ producto, mobile }) => {

	const navigate = useNavigate();

	const handleProductoClick = () => {
		navigate(`./${producto.idProducto}`);
	}

	if (mobile) {
		return (
			<div class="product-card card border br-12">

				<div class="row">

					<div class="col-4 pe-0">

						<div class="overflow-hidden h-100">

							<div class="card-img-top position-relative bg-white h-100" >
								<a href="http://localhost/wordpress/producto/standard-in-4a/" title="STANDARD - In: 4A" class="text-decoration-none position-absolute h-100 w-100"></a>
							</div>

						</div>

					</div>

					<div class="col-8 ps-0">

						<div class="card-body">

							<h2 class="card-text fw-bold mb-3">
								<a href="http://localhost/wordpress/producto/standard-in-4a/" title="STANDARD - In: 4A" class="text-decoration-none">
									STANDARD - In: 4A						</a>
							</h2>


							<div class="taxonomies mb-2">
								<div class="badge bg-success text-success bold cat me-1 mb-2"><a href="http://localhost/wordpress/drives/convertidor-de-frecuencia-cfw100/" rel="tag">Convertidor de Frecuencia CFW100</a></div> <div class="badge bg-success text-success cat me-1 mb-2"><a href="http://localhost/wordpress/drives/" rel="tag">Drives</a></div>
								<div class="badge bg-warning text-warning bold vol me-1 mb-2"><a href="http://localhost/wordpress/voltaje/200-240-v/" rel="tag">200-240 V</a></div>
								<div class="badge bg-danger text-danger bold rot me-1 mb-2"></div>
								<div class="badge bg-primary text-primary bold pot me-1 mb-2"><a href="http://localhost/wordpress/potencia/0-18-a-0-75-kw-0-25-a-1-hp/" rel="tag">0.18 a 0.75 kw (0.25 a 1 hp)</a></div>
							</div>

							<a role="button" tabindex="0" href="javascript:;" class="rounded-pill btn btn-primary add_pedido" data-id="1121">
								Agregar al pedido
							</a>

						</div>

					</div>

				</div>

			</div >
		)
	}

	return (
		<div className="product-card card border br-12">
			<div class="card-header p-0 overflow-hidden" style={{ cursor: "pointer" }} onClick={handleProductoClick}>
				<div className="card-img-top position-relative bg-white"
					style={{ height: "350px" }}>
					<img src={producto.urlImagen} alt={producto.nombre} style={{ width: "100%", height: "100%" }} />
				</div>
			</div>
			<div class="card-body">

				<h2 class="card-text fw-bold mb-3" style={{ cursor: "pointer" }} onClick={handleProductoClick}>
					<a href="http://localhost/wordpress/producto/motor-w22-ie1-trifasico-3000rpm-0-25cv/" title="Motor W22 IE1 - Trifásico - 3000rpm - 0.25cv" class="text-decoration-none">
						{producto.nombre}
					</a>
				</h2>

				<div class="description card-text mb-4">
					<p>{producto.descripcion}</p>
				</div>

				<div class="taxonomies mb-2">
					{producto.nombreTipoProductoAbuelo &&
						<div class="badge bg-success text-success cat me-1 mb-2">{producto.nombreTipoProductoAbuelo}</div>}
					{producto.nombreTipoProductoPadre &&
						<div class="badge bg-success text-success cat me-1 mb-2">{producto.nombreTipoProductoPadre}</div>}
					<div class="badge bg-success text-success cat me-1 mb-2">{producto.nombreTipoProducto}</div>
					{producto.voltaje &&
						<div class="badge bg-warning text-warning vol me-1 mb-2">{producto.voltaje}</div>}
					{producto.rotactionSincronica &&
						<div class="badge bg-danger text-danger rot me-1 mb-2">{producto.rotactionSincronica}</div>}
					{producto.potencia &&
						<div class="badge bg-primary text-primary pot me-1 mb-2">{producto.potencia}</div>}
					{producto.proteccion &&
						<div class="badge bg-primary text-secondary rot me-1 mb-2">{producto.proteccion}</div>}
					{producto.carcasa &&
						<div class="badge bg-primary text-info pot me-1 mb-2">{producto.carcasa}</div>}
					{producto.embalaje &&
						<div class="badge bg-primary text-primary pot me-1 mb-2">{producto.embalaje}</div>}
				</div>

				<a role="button" tabindex="0" href="javascript:;" class="rounded-pill btn btn-primary add_pedido" data-id="616">
					Agregar al pedido
				</a>

			</div>
		</div>
	);
};

export default ProductCard;