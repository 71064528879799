import React, { useState, useEffect } from 'react'
import { Combo } from 'components'
import { fetchUrl } from 'modules/util/functions'

const PotenciaCombo = (props) => {
	const [items, setItems] = useState([]);

	useEffect(() => {
		fetchUrl("/query/getPotencias", "get", {}, {
			success: (r) => {
				let items = r.data.potencias.map(x => ({
					value: x.idPotencia,
					label: x.nombre
				}));

				if (props.exclusions)
					items = items.filter(x => !props.exclusions.includes(x.value.toString()));

				setItems(items);
			}
		}, { block: false });
	}, []);

	return (
		<Combo
			{...props}
			items={items} />
	)
}

export default PotenciaCombo;

PotenciaCombo.defaultProps = {
	formControl: true,
	formManaged: false
};