import React from 'react'
import SetupTabWrapper from './SetupTabWrapper'
import { Tabs, Tab } from 'react-bootstrap'
import { Button } from 'components'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { faArrowCircleLeft } from '@fortawesome/free-solid-svg-icons'
// import { withRouter } from 'react-router'

const SetupTabsContainer = (props) => {
    const params = useParams();
    const location = useLocation();
    const navigate = useNavigate();
    const selectedId = params.id;
    const tabs = props.setupProps.tabs;
    const activeTabKey = params.tabKey ?? tabs[0].key;

    const handleTabSelect = (key) => {
        if (!params.tabKey)
            navigate(key);

        if (key !== params.tabKey)
            navigate(params.tabKey ? location.pathname.replace(params.tabKey, key) : key);
    }

    return (
        <div style={{ padding: "0px", position: "relative", right: "-7px" }}>
            <Button
                variant="secondary"
                style={{ position: "absolute", right: 0, top: "-5px" }}
                icon={faArrowCircleLeft}
                iconPosition="left"
                onClick={props.onBack}>
                Volver a la búsqueda
            </Button>
            <Tabs
                activeKey={activeTabKey}
                onSelect={handleTabSelect}>
                {tabs.map(t => {
                    const Comp = t.comp;

                    return (
                        <Tab
                            disabled={props.adding}
                            title={t.title}
                            eventKey={t.key}
                            key={t.key}
                            mountOnEnter
                            unmountOnExit>
                            <SetupTabWrapper
                                selectedId={selectedId}
                                adding={props.adding}
                                onCreate={props.onCreate}
                                onDelete={props.onDelete}>
                                <Comp />
                            </SetupTabWrapper>
                        </Tab>
                    )
                })}
            </Tabs>
        </div>
    )
}

export default SetupTabsContainer;