import React, { useMemo, useRef } from 'react'
import SetupTab from 'modules/setup/setupTab/SetupTab'
import { TextBox, Label, RemoveModal, TextArea, TipoProductoCombo, DecimalBox, FileUpload } from 'components'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { fetchUrl } from 'modules/util/functions'
import { useSetupTabValueContext, useSetupTabActionsContext } from 'modules/setup/setupTab/SetupTabContext'

const TipoProductoInfoTab = () => {
	const { selectedId, form } = useSetupTabValueContext();
	const { onDelete, onError, onSuccess } = useSetupTabActionsContext();
	const _removeModal = useRef();

	const configuration = useMemo(() => ({
		urls: {
			get: "/tipoProductoSetup/getTipoProductoInfo",
			save: "/tipoProductoSetup/saveTipoProductoInfo",
			create: "/tipoProductoSetup/createTipoProducto"
		},
		getRequest: () => {
			return {
				idTipoProducto: selectedId
			}
		},
		saveRequest: (form) => {
			return {
				idTipoProducto: selectedId,
				nombre: form.nombre,
				descripcion: form.descripcion,
				idTipoProductoPadre: form.idTipoProductoPadre,
				posicion: form.posicion,
				urlImagen: form.urlImagen
			}
		}
	}), [selectedId]);

	const actions = [{
		key: "delete",
		name: "Borrar",
		onClick: () => {
			handleDelete();
		}
	}];

	// Handlers
	const handleDelete = () => {
		const rq = {
			idTipoProducto: selectedId
		};

		_removeModal.current.open({
			title: "Eliminando tipoProducto",
			name: form.nombre,
			onYes: () => {
				fetchUrl("/tipoProductoSetup/deleteTipoProducto", "post", rq, {
					success: (r) => {
						onSuccess(r.message);
						onDelete();
					},
					error: (r) => {
						onError(r.errorMessage);
					}
				}, { block: false });
			}
		});
	}

	return (
		<React.Fragment>
			<RemoveModal
				ref={_removeModal} />

			<SetupTab
				configuration={configuration}
				actions={actions}>
				<Row>
					<Col md={4}>
						<FormGroup>
							<Label required>Nombre</Label>
							<TextBox
								name="nombre"
								placeholder="Nombre" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Tipo de Producto Padre</Label>
							<TipoProductoCombo
								name="idTipoProductoPadre"
								placeholder="Tipo de Producto Padre"
								exclusions={[selectedId]} />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label help="Posicion dentro de su tipo">Posicion</Label>
							<DecimalBox
								name="posicion"
								placeholder="Posicion" />
						</FormGroup>
						<FormGroup style={{ marginTop: "20px" }}>
							<Label>Descripcion</Label>
							<TextArea
								name="descripcion"
								placeholder="Descripcion" />
						</FormGroup>
					</Col>
					<Col md={4}>
						<FormGroup>
							<Label>Imagen</Label>
							<FileUpload
								name="urlImagen"
								placeholder="Imagen" />
						</FormGroup>
					</Col>
				</Row>
			</SetupTab>
		</React.Fragment>
	)
}

export default TipoProductoInfoTab;