import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import RotacionSincronicaInfoTab from './rotacionSincronicaInfo/RotacionSincronicaInfoTab'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

const RotacionSincronicaSetup = () => {

	const setupProps = {
		title: "RotacionSincronicas",
		icon: faSquare,
		addNewTitle: "Crear rotacionSincronica",
		searchUrl: "/rotacionSincronicaSetup/search",
		rowKey: "idRotacionSincronica",
		columns: [
			{ key: "idRotacionSincronica", name: "ID", sortable: true, width: 60 },
			{ key: "nombre", name: "Nombre", sortable: true },
		],
		tabs: [
			{ key: "info", title: "Info", comp: RotacionSincronicaInfoTab }
		]
	};

	return (
		<SetupPage
			setupProps={setupProps}
			search={Search} />
	)
}

export default RotacionSincronicaSetup;