import React, { useMemo, useRef } from 'react'
import SetupTab from 'modules/setup/setupTab/SetupTab'
import { TextBox, Label, RemoveModal } from 'components'
import { Row, Col, FormGroup } from 'react-bootstrap'
import { fetchUrl } from 'modules/util/functions'
import { useSetupTabValueContext, useSetupTabActionsContext } from 'modules/setup/setupTab/SetupTabContext'

const CarcasaInfoTab = () => {
	const { selectedId, form } = useSetupTabValueContext();
	const { onDelete, onError, onSuccess } = useSetupTabActionsContext();
	const _removeModal = useRef();

	const configuration = useMemo(() => ({
		urls: {
			get: "/carcasaSetup/getCarcasaInfo",
			save: "/carcasaSetup/saveCarcasaInfo",
			create: "/carcasaSetup/createCarcasa"
		},
		getRequest: () => {
			return {
				idCarcasa: selectedId
			}
		},
		saveRequest: (form) => {
			return {
				idCarcasa: selectedId,
				nombre: form.nombre
			}
		}
	}), [selectedId]);

	const actions = [{
		key: "delete",
		name: "Borrar",
		onClick: () => {
			handleDelete();
		}
	}];

	// Handlers
	const handleDelete = () => {
		const rq = {
			idCarcasa: selectedId
		};

		_removeModal.current.open({
			title: "Eliminando carcasa",
			name: form.nombre,
			onYes: () => {
				fetchUrl("/carcasaSetup/deleteCarcasa", "post", rq, {
					success: (r) => {
						onSuccess(r.message);
						onDelete();
					},
					error: (r) => {
						onError(r.errorMessage);
					}
				}, { block: false });
			}
		});
	}

	return (
		<React.Fragment>
			<RemoveModal
				ref={_removeModal} />

			<SetupTab
				configuration={configuration}
				actions={actions}>
				<Row>
					<Col md={4}>
						<FormGroup>
							<Label required>Nombre</Label>
							<TextBox
								name="nombre"
								placeholder="Nombre" />
						</FormGroup>
					</Col>
				</Row>
			</SetupTab>
		</React.Fragment>
	)
}

export default CarcasaInfoTab;