import React, { useState, useEffect } from 'react'
import Select from 'react-select'
import { Button } from 'components'
import { faLink } from '@fortawesome/free-solid-svg-icons'

const Combo = (props) => {
    const [value, setValue] = useState();

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    const handleChange = (opt) => {
        setValue(opt?.value);

        if (props.formControl && props.formManaged) {
            props.onFieldChange(opt?.value, props.name);
            props.onChange && props.onChange(opt);
        }
        else
            props.onChange && props.onChange(opt);
    }

    const handleLinkClick = () => {
        window.open(`${props.labelUrl}/${props.value}`, "_blank");
    }

    const opts = {
        options: props.exclusions ? props.items.filter(x => !props.exclusions.includes(x.value)) : props.items,
        onChange: handleChange,
        value: value ? props.items.find(x => x.value === value) : null,
        placeholder: props.placeholder,
        isClearable: props.clearable,
        isDisabled: props.disabled
    };

    const customStyles = {
        control: base => ({
            ...base,
            height: 34,
            minHeight: 34
        }),
        valueContainer: base => ({
            ...base,
            marginTop: "-5px"
        })
    };

    return (
        <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
                <Select
                    {...opts}
                    styles={customStyles} />
            </div>
            {props.labelUrl &&
                <Button
                    size="sm"
                    variant="secondary"
                    style={{ height: "34px" }}
                    icon={faLink}
                    disabled={!props.value}
                    onClick={handleLinkClick} />}
        </div>
    )
}

export default Combo;

Combo.defaultProps = {
    formControl: true,
    formManaged: false,
    exclusions: null,
    placeholder: "Seleccione...",
    clearable: true,
    labelUrl: null
};