import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import ProductoInfoTab from './productoInfo/ProductoInfoTab'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

const ProductoSetup = () => {

	const setupProps = {
		title: "Productos",
		icon: faSquare,
		addNewTitle: "Crear producto",
		searchUrl: "/productoSetup/search",
		rowKey: "idProducto",
		columns: [
			{ key: "idProducto", name: "ID", sortable: true, width: 60 },
			{ key: "nombre", name: "Nombre", sortable: true },
			{ key: "carcasa", name: "Carcasa" },
			{ key: "potencia", name: "Potencia" },
			{ key: "voltaje", name: "Voltaje" },
			{ key: "embalaje", name: "Embalaje" },
			{ key: "proteccion", name: "Proteccion" },
			{ key: "rotacionSincronica", name: "Rotacion Sincronica" },
		],
		tabs: [
			{ key: "info", title: "Info", comp: ProductoInfoTab }
		]
	};

	return (
		<SetupPage
			setupProps={setupProps}
			search={Search} />
	)
}

export default ProductoSetup;