

const Footer = () => {
	const logoUrls = "/images/logo.balsolutions.svg";

	return (
		<footer>

			<div class="container">

				<div class="info">

					<img src={logoUrls} alt="BALSOLUTIONS" />

					<p class="mb-0">
						Servicios eléctricos industriales.
						<br />
						<span class="text-primary">Empresa familiar, tercera generación, desde 1965</span>
					</p>

				</div>

			</div>

		</footer>

	)
}

export default Footer;