import React, { useMemo, useState } from 'react'
import PublicLayout from 'layout/PublicLayout'
import Home from 'pages/home/Home'
import Nosotros from 'pages/nosotros/Nosotros'
import Contacto from 'pages/contacto/Contacto'
import Servicios from 'pages/servicios/Servicios'
import Productos from 'pages/productos/Productos'
import Producto from '../pages/productos/Producto'
import Dashboard from 'crm/pages/dashboard/Dashboard'
import PotenciaSetup from 'crm/pages/potencia/PotenciaSetup'
import CarcasaSetup from 'crm/pages/carcasa/CarcasaSetup'
import ProteccionSetup from 'crm/pages/proteccion/ProteccionSetup'
import VoltajeSetup from 'crm/pages/voltaje/VoltajeSetup'
import RotacionSincronicaSetup from 'crm/pages/rotacionSincronica/RotacionSincronicaSetup'
import EmbalajeSetup from 'crm/pages/embalaje/EmbalajeSetup'
import TipoProductoSetup from 'crm/pages/tipoProducto/TipoProductoSetup'
import ProductoSetup from 'crm/pages/producto/ProductoSetup'
import Login from 'pages/login/Login'
import { UserContext } from 'modules/contexts/UserContext'
import { BrowserRouter, Routes as Switch, Route, Navigate } from 'react-router-dom'
import * as BrowserStorage from 'modules/BrowserStorage'
import * as Urls from 'modules/Urls'

const Routes = () => {
	const [user, setUser] = useState(BrowserStorage.get("user"));

	const renderSetupRoutes = () => {
		return (
			<>
				<Route path="new" />
				<Route path=":id" />
				<Route path=":id/:tabKey" />
			</>
		)
	}

	const userProviderValue = useMemo(() => ({
		user,
		setUser
	}), [user, setUser]);

	const ProtectedRoute = ({ element }) => {
		if (!user)
			return <Navigate to={Urls.Home} />;

		return element;
	};

	return (
		<UserContext.Provider value={userProviderValue}>
			<BrowserRouter>
				<Switch>

					<Route element={<PublicLayout />}>
						<Route exact path={Urls.Home} element={<Home />} />
						<Route exact path={Urls.Nosotros} element={<Nosotros />} />
						<Route exact path={Urls.Contacto} element={<Contacto />} />
						<Route exact path={Urls.Servicios} element={<Servicios />} />
						<Route exact path={Urls.Productos} element={<Productos />} />
						<Route path={`${Urls.Productos}/:id`} element={<Producto />} />
						<Route exact path={Urls.Login} element={<Login />} />
					</Route>

					<Route path={Urls.CRM} element={<ProtectedRoute element={<Dashboard />} />} >
						<Route path={Urls.PotenciaSetup} element={<PotenciaSetup />}>
							{renderSetupRoutes()}
						</Route>

						<Route path={Urls.CarcasaSetup} element={<ProtectedRoute element={<CarcasaSetup />} />}>
							{renderSetupRoutes()}
						</Route>

						<Route path={Urls.ProteccionSetup} element={<ProtectedRoute element={<ProteccionSetup />} />}>
							{renderSetupRoutes()}
						</Route>

						<Route path={Urls.EmbalajeSetup} element={<ProtectedRoute element={<EmbalajeSetup />} />}>
							{renderSetupRoutes()}
						</Route>

						<Route path={Urls.VoltajeSetup} element={<ProtectedRoute element={<VoltajeSetup />} />}>
							{renderSetupRoutes()}
						</Route>

						<Route path={Urls.RotacionSincronicaSetup} element={<ProtectedRoute element={<RotacionSincronicaSetup />} />}>
							{renderSetupRoutes()}
						</Route>

						<Route path={Urls.TipoProductoSetup} element={<ProtectedRoute element={<TipoProductoSetup />} />}>
							{renderSetupRoutes()}
						</Route>

						<Route path={Urls.ProductoSetup} element={<ProtectedRoute element={<ProductoSetup />} />}>
							{renderSetupRoutes()}
						</Route>
					</Route>

				</Switch>
			</BrowserRouter>
		</UserContext.Provider>
	)
}

export default Routes;