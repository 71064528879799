export const set = (key, value) => {
    if (!value)
        return;

    localStorage.setItem(key, JSON.stringify(value));
}

export const get = (key) => {
    const value = localStorage.getItem(key);

    if (value)
        return JSON.parse(value);
}

export const remove = (key) => {
    localStorage.removeItem(key);
}

export const clear = () => {
    localStorage.clear();
}