import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { fetchUrl } from 'modules/util/functions'
import * as Notifications from 'modules/Notifications'

const Producto = () => {
	const [producto, setProducto] = useState({});
	const { id } = useParams();

	useEffect(() => {
		fetchUrl("/query/getProducto", "get", { idProducto: id }, {
			success: (r) => {
				setProducto(r.data);
			}
		}, { block: false });
	}, [id]);
	console.log(producto)
	if (!producto)
		return <div>Producto no encontrado.</div>;

	return (
		<section class="single-product description pb-5">

			<div class="container">

				<div class="row">

					<div class="title col-12">

						<h1>
							{producto.nombre}
						</h1>

					</div>

					<div class="image col-12 col-md-6 col-lg-5">

						<div class="d-flex align-items-center position-relative">

							<img src={producto.urlImagen} class="img-responsive w-100" />

							<div class="taxonomies mb-2 position-absolute" style={{ top: "20px", left: "20px" }}>

								{producto.nombreTipoProductoAbuelo &&
									<div class="badge bg-success text-success bold cat me-1 mb-2 shadow">
										{producto.nombreTipoProductoAbuelo}
									</div>}

								{producto.nombreTipoProductoPadre &&
									<div class="badge bg-success text-success bold cat me-1 mb-2 shadow">
										{producto.nombreTipoProductoPadre}
									</div>}

								{producto.nombreTipoProducto &&
									<div class="badge bg-success text-success bold cat me-1 mb-2 shadow">
										{producto.nombreTipoProducto}
									</div>}
							</div>


						</div>

					</div>

					<div class="content col-lg-7 col-md-12 d-flex align-items-center">

						<div>

							<div class="row">

								<div class="title col-12">

									<h3>
										Descripción del producto
									</h3>

									<p>
										{producto.descripcion}
									</p>

								</div>

							</div>

							<a role="button" tabindex="0" href="javascript:;" class="rounded-pill btn btn-primary add_pedido" data-id="<?php echo $post_id; ?>">
								Agregar al pedido
							</a>

						</div>



					</div>

				</div>

			</div>

		</section>
	)
};

export default Producto;