import { Button, ContactForm } from "components";
import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { Link } from 'react-router-dom';
import * as Urls from 'modules/Urls';

import "styles/theme.css";

const productos = [
	{
		id: 1,
		nombre: "asdasdas33d",
		imagen: "/images/MKT_WMO_BR_IMAGE_1PHASE_STEELMOTOR_MUNSELLN1_ODP_56_B3D_1CAP_1200Wx1200H-9-1024x1024.jpg"
	},
	{
		id: 2,
		nombre: "asdasda2sd",
		imagen: "/images/MKT_WMO_BR_IMAGE_1PHASE_STEELMOTOR_MUNSELLN1_ODP_56_B3D_1CAP_1200Wx1200H-9-1024x1024.jpg"
	},
	{
		id: 3,
		nombre: "asdasda1sd",
		imagen: "/images/MKT_WMO_BR_IMAGE_1PHASE_STEELMOTOR_MUNSELLN1_ODP_56_B3D_1CAP_1200Wx1200H-9-1024x1024.jpg"
	},
	{
		id: 4,
		nombre: "asdas2223dasd",
		imagen: "/images/MKT_WMO_BR_IMAGE_1PHASE_STEELMOTOR_MUNSELLN1_ODP_56_B3D_1CAP_1200Wx1200H-9-1024x1024.jpg"
	}
];

const Home = () => {
	const [activeTab, setActiveTab] = useState(0);


	const handleTabClick = (index) => {
		setActiveTab(index);
	};

	const renderBanner = () => {
		return (
			<section className="home banner" >

				<div className="container">

					<div className="row">

						<div className="col-lg-6 col-md-12">

							<svg xmlns="http://www.w3.org/2000/svg" width="70px" height="70px" viewBox="0 0 49.844 46.617" className="mb-4" alt="isologo"><g id="Grupo_49" data-name="Grupo 49" transform="translate(-849 -360.691)"><path id="Trazado_66" data-name="Trazado 66" d="M141.909,46.617H131.637V10.272H112.608V0h14.65a14.693,14.693,0,0,1,14.65,14.65Z" transform="translate(756.936 360.691)" fill="currentColor" fill-rule="evenodd"></path><path id="Trazado_67" data-name="Trazado 67" d="M0,0H10.272V36.346H29.3V46.618H14.65A14.693,14.693,0,0,1,0,31.968Z" transform="translate(849 360.691)" fill="currentColor" fill-rule="evenodd"></path><path id="Trazado_68" data-name="Trazado 68" d="M92.095,72.243l.953,5.663,4.7-3.326L99,75.832l-3.332,4.68,5.676.973,0,1.768-5.663.953L99,88.91l-1.252,1.245-4.68-3.332L92.095,92.5l-1.768,0-.953-5.663-4.7,3.326-1.245-1.252,3.332-4.68-5.676-.973,0-1.768,5.663-.953-3.327-4.7,1.253-1.246,4.68,3.332.973-5.675Z" transform="translate(782.711 301.631)" fill="currentColor" fill-rule="evenodd"></path></g></svg>


							<h1 className="mb-4" style={{ fontWeight: "900" }}>
								Soluciones expertas en Motores Eléctricos.
							</h1>

							<div className="mb-4">
								Venta, reparación y mantenimiento de motores eléctricos de cualquier escala.
							</div>

							<Button>
								Asesoria Gratuita
							</Button>

						</div>

						<div className="col-lg-6 col-md-12 d-none d-md-block">

							<Link to={Urls.Servicios}>
								<Button
									variant="outline-secondary"
									style={{ color: "white", borderColor: "white" }}>
									Nuestros Servicios
								</Button>
							</Link>

							<p style={{ marginTop: "2rem" }}>
								Somos Asistentes Técnicos autorizados de motores WEG, por lo que brindamos servicio técnico oficial con la certificación apropiada para trabajar dichos motores.
							</p>

						</div>

					</div>

				</div>

			</section>
		)
	}

	const renderAboutUs = () => {
		const imageUrl = "/images/4-1024x576.jpg";

		return (
			<section className="home about-us">

				<div className="container container-md-fluid">

					<div className="row">

						<div className="mission col-lg-6 col-md-12 mb-5 mb-md-auto mt-md-auto">

							<h2 className="text-primary">
								Conoce nuestra empresa
							</h2>

							Resolvemos las necesidades  integrales de venta, reparación y mantenimiento, enfocándonos en maximizar la satisfacción del cliente mediante soluciones personalizadas, atención rápida y mejoras continuas en la fiabilidad y eficiencia de los equipos humanos e industriales.

							<h2 className="text-primary text-normal-dos mt-4">

								<svg style={{ marginRight: "1rem" }} xmlns="http://www.w3.org/2000/svg" width="34.805" height="34.805" viewBox="0 0 34.805 34.805" alt="asterisk"><path id="Trazado_27" data-name="Trazado 27" d="M44.537,22.828l1.637,9.729,8.081-5.714,2.139,2.152-5.723,8.04,9.75,1.672-.006,3.038L50.685,43.38l5.714,8.08L54.248,53.6l-8.04-5.723-1.672,9.75L41.5,57.621l-1.637-9.73-8.08,5.715-2.14-2.152,5.723-8.04-9.75-1.672.006-3.038,9.73-1.637-5.715-8.081,2.152-2.139,8.04,5.723,1.672-9.75Z" transform="translate(-25.615 -22.822)" fill="currentColor" fill-rule="evenodd"></path></svg>
								Como operamos

							</h2>

							Mantenimiento preventivo regular con calendarios automatizados según el tipo y uso de motor, sistema de gestión de servicios para regular eficazmente la manutención y reparación, facilitamos el seguimiento en tiempo real del estado de los trabajos de servicio. Brindamos acceso a plataformas en línea donde puedan solicitar servicios o productos, hacer seguimiento de sus equipos y acceder a informes de mantenimiento.

						</div>

						<div className="mission-image col-lg-6 col-md-12">
							<img src={imageUrl} className="w-100" />
						</div>

					</div>

				</div>

			</section>
		)
	}

	const renderProductos = () => {
		return (
			<section className="home products">
				<div className="container d-none d-md-block">
					<div className="title text-primary d-flex justify-content-between">
						<h2>Productos Destacados</h2>
						<a href="/productos/" className="text-decoration-none d-none d-md-block">
							VER TODOS LOS PRODUCTOS
						</a>
					</div>

					<div className="row">
						{/* Pestañas de productos */}
						<div className="product-tabs col-lg-6 col-md-12 d-flex align-items-center">
							<div className="w-100">
								<div className="flex-column justify-content-end align-items-end nav nav-pills" role="tablist">
									{productos.map((producto, index) => (
										<div className="nav-item" role="presentation" key={producto.ID}>
											<button
												role="tab"
												onClick={() => handleTabClick(index)}
												className={`nav-link ${activeTab === index ? 'active' : ''}`}
												aria-selected={activeTab === index}
												tabIndex="0"
												type="button"
											>
												{producto.nombre}
											</button>
										</div>
									))}
								</div>
							</div>
						</div>

						{/* Imagen del producto */}
						<div className="product-image col-lg-6 col-md-12">
							<div className="tab-content">
								{productos.map((producto, index) => (
									<div
										role="tabpanel"
										key={producto.ID}
										className={`fade tab-pane position-relative ${activeTab === index ? 'active show' : ''}`}
									>
										<img src={producto.imagen} alt={producto.nombre} style={{ height: "400px" }} />
										<a
											href={producto.link}
											target="_blank"
											rel="noopener noreferrer"
											className="tab-pane-overlay h-100 w-100 position-absolute d-flex justify-content-center align-items-center text-decoration-none text-white"
										>
											Ver Producto
										</a>
									</div>
								))}
							</div>
						</div>
					</div>
				</div>


				<div class="container d-block d-md-none">

					<div class="title text-primary d-flex justify-content-between">

						<h2>
							Productos Destacados
						</h2>

						<a href="/productos/" class="text-decoration-none d-none d-md-block">
							ASDSADSAD
						</a>

					</div>

					<div class="row">

						{productos.map(x => {
							return (
								<div class="col-6 mb-4">

									<div class="card border-primary">

										<div class="card-header bg-white border-0">

											<img src={x.imagen} alt="<?php echo $prod['producto']->post_title; ?>" style={{ height: "150Px" }} />

										</div>

										<div class="card-body border-0">

											<div class="text-center">

												<div>

													{x.nombre}

												</div>

												<a href="<?php echo get_permalink( $prod['producto']->ID ); ?>" target="_blank" class="btn btn-primary mt-3">

													Ver Producto

												</a>

											</div>

										</div>

									</div>

								</div>
							)
						})}



					</div>

					<div class="text-center text-primary row mt-5">

						<a href="/productos/" class="text-decoration-none d-block d-md-none">
							VER TODOS LOS PRODUCTOS
						</a>

					</div>

				</div>
			</section>
		);
	}

	const renderWeg = () => {
		const logoWeg = "/images/weg.svg";

		return (
			<section class="home weg">

				<div class="container">

					<div class="row">

						<div class="text-center col-12">

							<img src={logoWeg} alt="logoWeg" />

							<h2 class="text-primary">
								Distribuidores Oficiales de WEG en Argentina
							</h2>

							<p>
								Bal Solutions fue elegida por WEG para podes distribuir y asistir a sus consumidores dentro de nuestro país.
							</p>

							<a href="<?php echo $boton_distribuidor['url']; ?>" title="<?php echo $boton_distribuidor['title']; ?>" class="rounded-pill btn btn-primary">
								Mas informacion
							</a>

						</div>

					</div>

				</div>

			</section>
		)
	}

	const renderServices = () => {
		const imagenServicios = "/images/servicios.jpg";

		const servicios = [
			{
				titulo: "Bobinado de motores con freno:",
				descripcion: "Nuestros servicios incluyen el bobinado de motores con freno, asegurando un trabajo meticuloso y preciso para mantener o restaurar la funcionalidad de sus sistemas de frenado eléctrico."
			},
			{
				titulo: "Mantenimiento de transformadores In-situ:",
				descripcion: "Realizamos mantenimiento de transformadores in-situ, proporcionando servicios de limpieza, pruebas y reparaciones en el lugar para minimizar el tiempo de inactividad y maximizar la disponibilidad de sus equipos."
			},
			{
				titulo: "Reparación de transformadores potencia:",
				descripcion: "Nos especializamos en la reparación de transformadores de potencia, ofreciendo servicios de diagnóstico, mantenimiento y reparación para garantizar un funcionamiento seguro y eficiente de sus equipos."
			},
			{
				titulo: "Reparaciones de máquinas de soldar:",
				descripcion: "Contamos con experiencia en reparaciones de máquinas de soldar, ofreciendo soluciones rápidas y efectivas para mantener sus equipos en óptimas condiciones de funcionamiento."
			},
			{
				titulo: "Bobinado de bombas centrífugas y sumergibles:",
				descripcion: "Especializados en el bobinado de bombas centrífugas y sumergibles, proporcionamos soluciones eficientes para garantizar el rendimiento y la durabilidad de sus equipos, tanto en aplicaciones industriales como comerciales."
			},
			{
				titulo: "Bobinado de transformadores:",
				descripcion: "Ofrecemos servicios de bobinado de transformadores, utilizando técnicas avanzadas y materiales de alta calidad para garantizar la fiabilidad y eficiencia energética de sus transformadores."
			},
			{
				titulo: "Bobinado de motores con freno:",
				descripcion: "Nuestros servicios incluyen el bobinado de motores con freno, asegurando un trabajo meticuloso y preciso para mantener o restaurar la funcionalidad de sus sistemas de frenado eléctrico."
			}
		];

		return (
			<section class="home services">

				<div class="container">

					<div class="row">

						<div class="title col-12 col-lg-5 col-md-12">

							<h2 class="mb-4 text-primary">
								Nuestros Servicios
							</h2>

							<img src={imagenServicios} alt="Nuestros Servicios" />

						</div>

						<div class="carousel col-lg-7 col-md-12 d-flex align-items-end">

							<div class="swiper swiper-initialized swiper-vertical swiper-autoheight swiper-backface-hidden swipper-services">

								<div class="swiper-wrapper" style={{ height: "241px" }}>
									<Swiper
										direction={'vertical'}
										pagination={{
											clickable: true,
										}}
										modules={[Pagination]}
										className="mySwiper"
									>
										{servicios.map((servicio, index) => (
											<SwiperSlide key={index}>
												<h4 className="text-primary">
													{servicio.titulo}
												</h4>
												<div style={{ width: "90%" }}>
													{servicio.descripcion}
												</div>
											</SwiperSlide>
										))}
									</Swiper>

								</div>

								<div class="swiper-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-vertical"></div>

							</div>
						</div>

					</div>

				</div>

			</section>
		)
	}

	const renderContactBanner = () => {
		return (
			<section class="home contact-banner">

				<div class="container container-md-fluid">

					<div class="row justify-content-between">

						<div class="text-light title col-12 col-lg-6 col-md-8">

							<svg xmlns="http://www.w3.org/2000/svg" width="34.805" height="34.805" viewBox="0 0 34.805 34.805">
								<path id="Trazado_27" data-name="Trazado 27" d="M44.537,22.828l1.637,9.729,8.081-5.714,2.139,2.152-5.723,8.04,9.75,1.672-.006,3.038L50.685,43.38l5.714,8.08L54.248,53.6l-8.04-5.723-1.672,9.75L41.5,57.621l-1.637-9.73-8.08,5.715-2.14-2.152,5.723-8.04-9.75-1.672.006-3.038,9.73-1.637-5.715-8.081,2.152-2.139,8.04,5.723,1.672-9.75Z" transform="translate(-25.615 -22.822)" fill="currentColor" fill-rule="evenodd"></path>
							</svg>

							<div>

								<h2>
									¿Qué te brindamos?
								</h2>

								Evaluación de las necesidades de tu negocio: Recomendaciones técnicas, inversión y beneficio, educación sobre mantenimiento preventivo y eficiencia energética para reducir costos operativos.

							</div>

						</div>

						<div class="col-12 col-lg-2 col-md-4 d-flex justify-content-center justify-content-md-end align-items-center">

							<a href="<?php echo $boton_agendar['url']; ?>" ttile="Asesoría Gratuita" class="rounded-pill btn btn-light btn-lg">
								Asesoría Gratuita
							</a>

						</div>

					</div>

				</div>

			</section>
		)
	}

	const renderContactUs = () => {
		return (
			<>
				<section class="home contact-us my-0">

					<div class="px-md-0 container container-fluid">

						<div class="row">

							<div class="col-lg-6 col-md-12">

								<div
									className="h-100 w-100"
									style={{
										backgroundImage: "url('/images/2.jpg')",
										backgroundSize: "cover",
										backgroundPosition: "center"
									}}
									alt="contacto"
								></div>

							</div>

							<div class="form col-lg-6 col-md-12">

								<h2>
									Contactanos.
								</h2>

								Consultanos sobre lo que necesites y te responderemos a la brevedad.

								<ContactForm />
							</div>

						</div>

					</div>

				</section>
			</>
		)
	}

	const renderMap = () => {
		return (
			<section class="home map">
				<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2809.094996550697!2d-60.148417123351344!3d-33.398171477784395!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95b75ee8cab96da7%3A0x7105c1cfb75d1543!2sBalsolutions!5e0!3m2!1sen!2sar!4v1718820838783!5m2!1sen!2sar" width="100%" height="450" loading="lazy" referrerpolicy="no-referrer-when-downgrade" title="map" style={{ border: "0px" }}></iframe>
			</section>
		)
	}

	return (
		<>
			{renderBanner()}
			{renderAboutUs()}
			{renderProductos()}
			{renderWeg()}
			{renderServices()}
			{renderContactBanner()}
			{renderContactUs()}
			{renderMap()}
		</>
	)
};

export default Home;