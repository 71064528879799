import React, { useState, useEffect } from 'react'
import Banner from './Banner';
import { fetchUrl } from 'modules/util/functions'
import ProductosHeader from './ProductosHeader';
import ProductCard from './ProductCard';

const Productos = () => {
	const [productos, setProductos] = useState([]);
	const [tipoProductos, setTipoProductos] = useState([]);
	const [tipoProducto, setTipoProducto] = useState();
	const [tipoProductoRaiz, setTipoProductoRaiz] = useState();
	const [totalCount, setTotalCount] = useState(0);
	const [filtros, setFiltros] = useState({});

	useEffect(() => {
		fetchUrl("/query/getTipoProductos", "get", {}, {
			success: (r) => {
				setTipoProductos(r.data.tipoProductos);
			}
		});

		fetchUrl("/query/getProductos", "get", {}, {
			success: (r) => {
				setProductos(r.data.productos);
				setTotalCount(r.data.totalCount);
			}
		});
	}, []);

	useEffect(() => {
		fetchUrl("/query/getProductos", "get", {
			...filtros
		}, {
			success: (r) => {
				setProductos(r.data.productos);
				setTotalCount(r.data.totalCount);
			}
		});
	}, [filtros]);

	const getPadre = (tipoProducto) => tipoProductos.find(tp => tp.idTipoProducto === tipoProducto.idTipoProductoPadre);

	// Handlers
	const handleSetTipoProducto = (tipoProducto) => {
		setTipoProducto(tipoProducto);
		setFiltros({
			...filtros,
			idTipoProducto: tipoProducto.idTipoProducto
		});

		if (!tipoProducto.idTipoProductoPadre)
			setTipoProductoRaiz(tipoProducto);
	}

	const handleRemoveTipoProducto = () => {
		if (tipoProducto.idTipoProductoPadre) {
			const padre = getPadre(tipoProducto);

			setTipoProducto(padre);
			setFiltros({
				...filtros,
				idTipoProducto: padre.idTipoProducto
			});
		}
		else {
			setTipoProducto(null);
			setTipoProductoRaiz(null);
			setFiltros({});
		}
	}

	const renderProductos = () => {
		const hijos = tipoProductos.filter(tp => tp.idTipoProductoPadre === tipoProducto?.idTipoProducto);

		return (
			<section class="products product-grid">

				<div class="container">

					<div class="row">

						<div class="filter col-lg-3 col-md-12 mb-4">

							{tipoProducto &&

								<div class="filter-apply">

									<div class="card mb-4 br-12">

										<div class="card-header" style={{ backgroundColor: "#cfe2ff" }}>

											<p class="mb-0 text-dark">
												<b>Filtros aplicados</b>
											</p>

										</div>

										<div class="card-body pb-0">

											<div class="col-12 mb-3">

												{!tipoProducto.idTipoProductoPadre ? "Tipo: " : getPadre(tipoProducto).nombre + ": "}
												<div class="badge bg-success text-success text-decoration-none p-2 me-3 text-normal"
													onClick={handleRemoveTipoProducto}>
													<div class="d-flex align-items-center mb-0">
														<div class="mb-0 me-1">{tipoProducto.nombre}</div>
														<div class="text-normal mb-0">x</div>
													</div>
												</div>

											</div>




											<p style={{ color: "var(--filter-link-color)" }}>
												<i class="bi bi-check-circle"></i> <b>{totalCount}</b> productos encontrados.
											</p>

										</div>

									</div>

								</div>}


							<div class="accordion" id="accordionpanelsStayOpenExample">

								{!tipoProducto &&
									<div class="accordion-item mb-0">

										<h5 class="accordion-header" id="panelsStayOpen-headingTipo">

											<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTipo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTipo">
												<b>
													Tipo
												</b>
											</button>

										</h5>

										<div id="panelsStayOpen-collapseTipo" class="accordion-collapse collapse show mb-0" aria-labelledby="panelsStayOpen-headingTipo">

											<div class="accordion-body mb-0">

												<ul id="cat-list" class="list-terms ps-0">

													{tipoProductos.filter(tp => !tp.idTipoProductoPadre).sort((a, b) => a.posicion - b.posicion).map((tp) => (
														<li>
															<div onClick={() => handleSetTipoProducto(tp)} style={{ cursor: "pointer" }}>
																<input class="form-check-input filter" type="radio" name="tipo" checked={tp.idTipoProducto === tipoProducto?.idTipoProducto} />&nbsp;{tp.nombre}
															</div>
														</li>
													))}

												</ul>

											</div>

										</div>

									</div>}

								{hijos && hijos.length > 0 &&

									<div class="accordion-item mb-0">

										<h5 class="accordion-header" id="panelsStayOpen-heading">
											<button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapse" aria-expanded="true" aria-controls="panelsStayOpen-collapse">
												<b>{tipoProducto.nombre}</b>
											</button>
										</h5>

										<div id="panelsStayOpen-collapse" class="accordion-collapse collapse show mb-0" aria-labelledby="panelsStayOpen-heading">

											<div class="accordion-body mb-0">

												<ul id="cat-list" class="list-terms ps-0">

													{hijos.sort((a, b) => a.posicion - b.posicion).map((tp) => (
														<li>
															<div onClick={() => handleSetTipoProducto(tp)} style={{ cursor: "pointer" }}>
																<input class="form-check-input filter" type="radio" name="tipo" checked={tp.idTipoProducto === tipoProducto?.idTipoProducto} />&nbsp;{tp.nombre}
															</div>
														</li>
													))}

												</ul>

											</div>

										</div>

									</div>}

							</div>

						</div>

						<div class="col-lg-9 col-md-12">

							<div class="row">

								{productos.map(p => {
									return (
										<div className="col-12 col-md-4 pb-4 d-none d-md-block">
											<ProductCard
												producto={p}
												tipoProductos={tipoProductos} />
										</div>
									)
								})}

							</div>

							<div class="row">

								{productos.map(p => {
									return (
										<div className="col-12 mb-3 d-block d-md-none">
											<ProductCard
												producto={p}
												tipoProductos={tipoProductos}
												mobile />
										</div>
									)
								})}

							</div>

						</div>

					</div>

				</div>

			</section>
		)
	}

	return (
		<>
			<Banner
				tipoProducto={tipoProducto} />
			<ProductosHeader
				tipoProductos={tipoProductos.filter(tp => !tp.idTipoProductoPadre).sort((a, b) => a.posicion - b.posicion)}
				tipoProducto={tipoProductoRaiz}
				onSetTipoProducto={handleSetTipoProducto} />
			{renderProductos()}
		</>
	)
}

export default Productos;