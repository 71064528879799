import React from 'react'
import { Form, InputGroup } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const TextBox = (props) => {
    // Handlers
    const handleChange = (e) => {
        if (props.formControl && props.formManaged)
            props.onFieldChange && props.onFieldChange(e.target.value, props.name);
        else
            props.onChange && props.onChange(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter")
            props.onEnter && props.onEnter();
    }

    // Control props
    const opts = {
        disabled: props.disabled,
        value: props.value || "",
        placeholder: props.placeholder,
        name: props.name,
        type: "text",
        onChange: handleChange,
        onKeyDown: handleKeyDown,
        autoComplete: "off"
    };

    const textBox = (
        <Form.Control
            {...opts} />
    );

    if (props.icon) {
        return (
            <InputGroup>
                <InputGroup.Text>
                    <FontAwesomeIcon
                        icon={props.icon} />
                </InputGroup.Text>
                {textBox}
            </InputGroup>
        )
    }

    return textBox;
}

export default TextBox;

// Default props
TextBox.defaultProps = {
    formControl: true,
    formManaged: false
};