import React, { useState, useImperativeHandle, forwardRef } from 'react'
import { Modal, Button } from 'react-bootstrap'

const RemoveModal = forwardRef((props, ref) => {
	const initialState = () => {
		return {
			visible: false,
			title: null,
			name: null,
			onYes: null,
			onNo: null
		}
	};

	const [state, setState] = useState(initialState());

	// API
	useImperativeHandle(ref, () => ({
		open: (params) => {
			setState(ps => ({
				...ps,
				...params,
				visible: true
			}));
		}
	}));

	const yes = () => {
		state.onYes?.();

		setState(initialState);
	}

	const no = () => {
		state.onNo?.();

		setState(initialState);
	}

	const confirmText = "Esta seguro que quiere <strong>eliminar</strong> <big>" + state.name + "</big>?";

	return (
		<React.Fragment>
			<Modal
				show={state.visible}
				onHide={no}
				backdrop="static">
				<Modal.Header>
					<Modal.Title>{state.title}</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div dangerouslySetInnerHTML={{ __html: confirmText }} />
				</Modal.Body>
				<Modal.Footer>
					<Button variant="link" onClick={no} autoFocus>Cancelar</Button>
					<Button variant="danger" onClick={yes}>Si, eliminar!</Button>
				</Modal.Footer>
			</Modal>
		</React.Fragment>
	)
})

export default RemoveModal;