import React from 'react'
import TextBox from '../textBox/TextBox'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

const SearchBox = (props) => {
	return (
		<div style={{ position: "relative" }}>
			<TextBox
				{...props} />
			<FontAwesomeIcon
				icon={faSearch}
				style={{ position: "absolute", right: 8, top: 9, color: "gray" }} />
		</div>
	)
}

export default SearchBox;

SearchBox.defaultProps = {
	formControl: true,
	formManaged: false
}