import React from 'react'
import { Form } from 'react-bootstrap'

const TextArea = (props) => {
    // Handlers
    const handleChange = (e) => {
        if (props.formControl && props.formManaged)
            props.onFieldChange && props.onFieldChange(e.target.value, props.name);
        else
            props.onChange && props.onChange(e.target.value);
    }

    const handleKeyDown = (e) => {
        if (e.key === "Enter")
            props.onEnter && props.onEnter();
    }

    // Control props
    const opts = {
        disabled: props.disabled,
        value: props.value || "",
        placeholder: props.placeholder,
        name: props.name,
        as: "textarea",
        onChange: handleChange,
        onKeyDown: handleKeyDown,
        autoComplete: "off",
        rows: props.rows,
        style: props.style
    };

    return (
        <Form.Control
            {...opts} />
    );
}

export default TextArea;

// Default props
TextArea.defaultProps = {
    formControl: true,
    formManaged: false,
    rows: 3
};