import React, { useState, useContext } from 'react'
import { fetchUrl } from 'modules/util/functions'
import { useNavigate } from 'react-router-dom'
import { UserContext } from 'modules/contexts/UserContext'
import * as BrowserStorage from 'modules/BrowserStorage'

const Login = () => {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [error, setError] = useState();
	const { setUser } = useContext(UserContext);
	const navigate = useNavigate();

	const hanelEmailChange = (e) => {
		setEmail(e.target.value);
	}

	const hanelPasswordChange = (e) => {
		setPassword(e.target.value);
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		if (!email || !password) {
			setError('Debe completar los campos');
			return;
		}

		fetchUrl("/login/login", "post", {
			nombreUsuario: email,
			contraseña: password
		}, {
			success: (r) => {
				// Persist user in local storage
				BrowserStorage.set("user", r.data);

				// Set in context
				setUser(r.data);

				navigate("/crm");
			},
			error: (r) => {
				setError(r.errorMessage);
			}
		});
	}

	return (
		<main role="main">

			<section role="banner" id="inicio" class="bg-light section">

				<div class="container">

					<div class="d-flex justify-content-center">

						<div class="col-12 col-md-5 py-5">

							<div class="br-12 bg-white mb-3 box-shadow">

								<div class="p-4 p-md-5">

									<h1 class="text-normal-dos text-dark bold mb-4 text-center">
										Ingresar
									</h1>

									{error && <div style={{ marginBottom: "10px" }} className="text-danger">{error}</div>}


									<div class="row" id="wp_login_form">

										<div class="col-12">

											<form onSubmit={handleSubmit} name="custom-login-form" id="custom-login-form">
												<p class="login-username">
													<label for="user_login">Correo electrónico:</label>
													<input
														type="text"
														name="log"
														id="user_login"
														autocomplete="username"
														className="form-control text-dark border-dark py-2"
														onChange={hanelEmailChange} />
												</p>

												<p class="login-password">
													<label for="user_pass">Contraseña:</label>
													<input
														type="password"
														name="pwd"
														id="user_pass"
														autocomplete="current-password"
														spellcheck="false"
														className="form-control text-dark border-dark py-2"
														required=""
														size="20"
														onChange={hanelPasswordChange} />
												</p>

												{/* <p class="login-remember">
													<label>
														<input name="rememberme" type="checkbox" id="rememberme" value="forever" /> Recuérdame
													</label>
												</p> */}

												<p class="form-group text-center" >
													<input type="submit" name="wp-submit" id="wp-submit" className="btn btn-primary rounded-5" value="Acceder" style={{ marginTop: "25px" }} />
													{/* <input type="hidden" name="redirect_to" value="http://localhost/wordpress/crm" /> */}
												</p>

											</form>

										</div>
									</div>

								</div>

							</div>

						</div>

					</div>

				</div>

			</section>

		</main>
	)
}

export default Login;