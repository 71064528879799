import { Outlet } from 'react-router-dom';
import Header from 'layout/Header'
import Footer from 'layout/Footer'

function Layout() {
	return (
		<>
			<Header />
			<Outlet />
			<Footer />
		</>
	);
}

export default Layout;
