import React from 'react'
import { Form, Button } from 'components'
import { Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faSync } from '@fortawesome/free-solid-svg-icons'
import { useSetupSearchValueContext, useSetupSearchActionsContext } from './SetupSearchContext'

const SetupSearch = (props) => {
    const { form } = useSetupSearchValueContext();
    const { onFormChange, onReset, onSubmit } = useSetupSearchActionsContext();

    // React
    const renderButtons = () => {
        return (
            <div style={{ float: "right" }}>
                <Button
                    variant="secondary"
                    type="reset">
                    <FontAwesomeIcon
                        icon={faSync} />
                </Button>
                <Button
                    variant="primary"
                    type="submit"
                    style={{ marginLeft: "15px", width: "100px" }}>
                    <FontAwesomeIcon
                        icon={faSearch} />
                </Button>
            </div>
        )
    }

    return (
        <Form
            onChange={onFormChange}
            onReset={onReset}
            onSubmit={onSubmit}
            value={form}>
            <Row>
                <Col md={10}>
                    {props.children}
                </Col>
                <Col md={2}>
                    {renderButtons()}
                </Col>
            </Row>
        </Form>
    )
}

export default SetupSearch;