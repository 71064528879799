import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import CarcasaInfoTab from './carcasaInfo/CarcasaInfoTab'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

const CarcasaSetup = () => {

	const setupProps = {
		title: "Carcasas",
		icon: faSquare,
		addNewTitle: "Crear carcasa",
		searchUrl: "/carcasaSetup/search",
		rowKey: "idCarcasa",
		columns: [
			{ key: "idCarcasa", name: "ID", sortable: true, width: 60 },
			{ key: "nombre", name: "Nombre", sortable: true },
		],
		tabs: [
			{ key: "info", title: "Info", comp: CarcasaInfoTab }
		]
	};

	return (
		<SetupPage
			setupProps={setupProps}
			search={Search} />
	)
}

export default CarcasaSetup;