import React from 'react'
import DataGrid from 'react-data-grid';

import 'react-data-grid/lib/styles.css';

const SetupList = (props) => {
    // Handlers
    const handleRowClick = (cell) => {
        props.onSelect(cell.row[props.rowKey]);
    }


    const handleGridSort = (sortData) => {
        const sortColumn = sortData[0].columnKey;
        const columnName = sortColumn[0].toUpperCase() + sortColumn.slice(1);

        props.onGridSort(columnName, sortData[0].direction);
    }

    // React
    const columns = props.columns.map(x => ({
        ...x,
        resizable: true
    }));

    return (
        <DataGrid
            onCellClick={handleRowClick}
            onSortColumnsChange={handleGridSort}
            rowKey={props.rowKey}
            columns={columns}
            rows={props.rows}
            rowsCount={props.rows?.length}
            enableCellSelect={false}
            style={{ height: "500px", width: "100%" }} />
    )
}

export default SetupList;