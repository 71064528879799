import React from 'react'

const Header = (props) => {
    return (
        <React.Fragment>
            <div>
                {props.children}
            </div>
            <hr />
        </React.Fragment>
    )
}

export default Header;