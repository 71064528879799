import React, { useEffect, useState } from 'react'
import Form from 'react-bootstrap/Form';
import Button from '../button/Button';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const FileUpload = (props) => {
	const preset_name = "default_image";
	const cloud_name = "dd2dtg7tb"

	const [image, setImage] = useState('');
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		if (props.value) {
			setImage(props.value);
		}
	}, [props.value]);


	const uploadImage = async (e) => {
		const files = e.target.files;
		const data = new FormData();
		data.append('file', files[0]);
		data.append('upload_preset', preset_name);

		setLoading(true);

		try {
			const response = await fetch(`https://api.cloudinary.com/v1_1/${cloud_name}/image/upload`, {
				method: 'POST',
				body: data
			});
			console.log('response:', response)

			const file = await response.json();
			console.log('file:', file)
			setLoading(false);
			setImage(file.secure_url);

			handleChange(file.secure_url);
		} catch (error) {
			console.error('Error uploading image:', error);
			setLoading(false);
		}

	}

	const handleChange = (url) => {
		console.log('url:', url)
		if (props.formControl && props.formManaged)
			props.onFieldChange && props.onFieldChange(url, props.name);
		else
			props.onChange && props.onChange(url);
	}

	if (loading) {
		return (
			<div>Cargando...</div>
		)
	}

	const handleRemove = () => {
		setImage('');
		handleChange(null);
	}

	if (image) {
		return (
			<div style={{ display: "flex", flexDirection: "column" }}>
				<Button
					icon={faTrash}
					variant="danger"
					style={{ width: "50px", height: "40px", marginBottom: "10px" }}
					onClick={handleRemove} />
				<img width={250} src={image} alt="imagen subida" />
			</div>
		)
	}

	return (
		<Form.Group controlId="formFile" className="mb-3">
			<Form.Control
				type="file"
				onChange={uploadImage} />
		</Form.Group>
	)
}

export default FileUpload;

FileUpload.defaultProps = {
	formControl: true,
	formManaged: false
};