import { Button as BsButton } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Button = (props) => {

	const opts = {
		variant: props.variant,
		size: props.size,
		style: props.style,
		onClick: props.onClick,
		type: props.type ?? "button",
		disabled: props.disabled
	};

	if (props.icon) {
		if (props.iconPosition === "left") {
			return (
				<BsButton {...opts}>
					<FontAwesomeIcon
						icon={props.icon}
						style={{ marginRight: "8px" }} />
					{props.children}
				</BsButton>
			)
		} else if (props.iconPosition === "right") {
			return (
				<BsButton {...opts}>
					{props.children}
					<FontAwesomeIcon
						icon={props.icon}
						style={{ marginLeft: "8px" }} />
				</BsButton>
			)
		} else {
			return (
				<BsButton {...opts}>
					{props.children}
					<FontAwesomeIcon icon={props.icon} />
				</BsButton>
			)
		}
	}

	return (
		<BsButton {...opts}>
			{props.children}
		</BsButton>
	)
}

export default Button;