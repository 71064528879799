export const Home = '/';
export const Nosotros = '/nosotros';
export const Productos = '/productos';
export const Contacto = '/contacto';
export const Servicios = '/servicios';
export const CRM = 'crm';
export const PotenciaSetup = 'potencia';
export const ProteccionSetup = 'proteccion';
export const CarcasaSetup = 'carcasa';
export const VoltajeSetup = 'voltaje';
export const RotacionSincronicaSetup = 'rotacionSincronica';
export const EmbalajeSetup = 'embalaje';
export const TipoProductoSetup = 'tipoProducto';
export const ProductoSetup = 'productos';
export const Login = 'login';