import React from 'react'
import Search from './Search'
import SetupPage from 'modules/setup/SetupPage'
import TipoProductoInfoTab from './tipoProductoInfo/TipoProductoInfoTab'
import { faSquare } from '@fortawesome/free-solid-svg-icons'

const TipoProductoSetup = () => {

	const setupProps = {
		title: "Tipos de Productos",
		icon: faSquare,
		addNewTitle: "Crear Tipo de Producto",
		searchUrl: "/tipoProductoSetup/search",
		rowKey: "idTipoProducto",
		columns: [
			{ key: "idTipoProducto", name: "ID", sortable: true, width: 60 },
			{ key: "nombre", name: "Nombre", sortable: true },
			{ key: "nombreTipoProductoPadre", name: "Tipo Padre" },
		],
		tabs: [
			{ key: "info", title: "Info", comp: TipoProductoInfoTab }
		]
	};

	return (
		<SetupPage
			setupProps={setupProps}
			search={Search} />
	)
}

export default TipoProductoSetup;