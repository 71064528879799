import React, { useState, useEffect } from 'react'

const Form = (props) => {
	const [form, setForm] = useState(props.value || {});

	useEffect(() => {
		setForm(props.value || {});
	}, [props.value]);

	// Handlers
	const handleFieldChange = (value, name) => {
		const newForm = {
			...form,
			[name]: value
		};

		setForm(newForm);

		props.onChange(newForm);
	}

	const handleSubmit = (e) => {
		e.preventDefault();

		props.onSubmit && props.onSubmit(form);
	}

	const handleReset = () => {
		props.onReset && props.onReset();
	}

	// Private
	const cloneChildren = (children) => {
		if (!Array.isArray(children))
			return cloneChild(children);

		return React.Children.map(children, (child) => {
			return cloneChild(child);
		})
	}

	const cloneChild = (child) => {
		if (child?.type === undefined)
			return child;

		let childProps = {};

		if (child.props.formControl && child.props.name) {
			const value = form[child.props.name];

			childProps = {
				...child.props,
				formManaged: true,
				value,
				onFieldChange: handleFieldChange
			};
		}

		if (child.props.children) {
			childProps = {
				...childProps,
				children: cloneChildren(child.props.children)
			};
		}

		return React.cloneElement(child, childProps);
	}

	return (
		<form
			onSubmit={handleSubmit}
			onReset={handleReset}>
			{cloneChildren(props.children)}
		</form>
	)
}

export default Form;