import React, { useState, forwardRef, useImperativeHandle } from 'react'
import BlockUi from 'react-block-ui'
// import { Loader } from 'react-loaders';
import { RotatingLines } from 'react-loader-spinner'
import 'react-block-ui/style.css'
// import 'loaders.css/loaders.min.css'

let blockRef;

export const refHook = (ref) => {
	blockRef = ref;
}

export const block = () => {
	blockRef && blockRef.update(true);
}

export const unBlock = () => {
	blockRef && blockRef.update(false);
}

export const Block = forwardRef((props, ref) => {
	const [blocking, setBlocking] = useState(false);

	useImperativeHandle(ref, () => ({
		update(value) {
			setBlocking(value);
		}
	}));

	const getLoader = () => {

		return (
			// <div style={{ marginLeft: "50%" }}>
			<RotatingLines
				height="30"
				width="30"
				radius="9"
				strokeColor="#2f2feb"
				ariaLabel="three-dots-loading"
				wrapperStyle
				wrapperClass
			/>
			// </div>
		)
	}

	return (
		<BlockUi
			tag="div"
			blocking={blocking}
			loader={getLoader()}>
			{props.children}
		</BlockUi>
	)
});