import { createContext, useContext } from 'react'

export const SetupTabValueContext = createContext(null);

export const useSetupTabValueContext = () => {
    const context = useContext(SetupTabValueContext);

    if (!context) {
        throw new Error('SetupTabValueContext cannot be used outside the SetupTabValueContext.Provider');
    }

    return context;
};

export const SetupTabActionsContext = createContext(null);

export const useSetupTabActionsContext = () => {
    const context = useContext(SetupTabActionsContext);

    if (!context) {
        throw new Error('SetupTabActionsContext cannot be used outside the SetupTabActionsContext.Provider');
    }

    return context;
};
