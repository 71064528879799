import React from 'react'

const Banner = ({ tipoProducto }) => {
	return (
		<section class="banner productos d-flex align-items-center" role="banner">

			<div class="container">

				<div class="row">

					<div class="content col-12 ms-3">

						<h1 style={{ fontWeight: "900" }}>
							{tipoProducto?.nombreTipoProducto ?? "Nuestros Productos"}
						</h1>

						<p class="mb-0">
							{tipoProducto ? tipoProducto.descripcion : "Somos Asistente Técnico autorizado de motores WEG de línea estándar y antiexplosivos."}
						</p>

					</div>

				</div>

			</div>

		</section>
	)
}

export default Banner;