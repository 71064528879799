import React from 'react'

const Box = (props) => {
    const style = {
        backgroundColor: "white",
        borderTop: "3px solid #3c8dbc",
        borderRadius: "0.25rem",
        margin: "8px",
        boxShadow: "0 0 1px rgba(0, 0, 0, .125), 0 1px 3px rgba(0, 0, 0, .2)",
        padding: "10px",
    };

    return (
        <div style={style}>
            {props.children}
        </div>
    )
}

export default Box;